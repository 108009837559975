let listExtension = [
    "ai",
    "avi",
    "css",
    "csv",
    "dfb",
    "doc",
    "dwg",
    "exe",
    "fla",
    "html",
    "iso",
    "jpg",
    "js",
    "json",
    "mp3",
    "mp4",
    "pdf",
    "png",
    "ppt",
    "psd",
    "rar",
    "rtf",
    "svg",
    "txt",
    "xls",
    "xml",
    "zip"
]

export default listExtension