import { pdf, PDFViewer } from "@react-pdf/renderer";
import React, { Component } from "react"
import { useParams } from "react-router-dom";
import { Api } from "../../AWS/aws";
import Loading from "../../Components/Loading";
import PDF from "../../Components/PDF";
import "./Resume.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Resume extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            info: false
        };
    }

    getInfoPDF = (id) => {
        return Api({
            path: `/archivo/${id}`
        })
    }

    componentDidMount() {
        this.getInfoPDF(this.props.params.id).then((res) => {
            console.log(res);
            if (res.code === 0) {
                this.setState({ isLoading: false, info: res.body })
            } else {
                this.setState({ isLoading: false })
            }
        })
    }

    render() {
        return (
            <div className="Resume">
                {
                    this.state.isLoading ?
                        <div>
                            <Loading disabledAnimation />
                        </div>
                        :
                        this.state.info ?
                            <PDFViewer style={{ width: "100%", height: "100%" }}>
                                <PDF data={this.state.info} />
                            </PDFViewer>
                            :
                            <>Error</>
                }
            </div>
        )
    }
}

export default withParams(Resume);