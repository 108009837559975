import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Api } from "../../AWS/aws"
import { Button, FormControl, Row, Col, Form } from "react-bootstrap";
import Loading from "../../Components/Loading"
import Swal from 'sweetalert2'
import "./CreateStudent.css"

const CreateStudent = ({ user }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [nombre, setNombre] = useState("")
    const [rut, setRut] = useState("")
    const [email, setEmail] = useState("")
    const [contraseña, setContraseña] = useState("")
    const [rol, setRol] = useState("STUDENT")

    const navigate = useNavigate()

    const postStudent = (body) => {
        return Api({
            path: `/teacher/student`,
            body: body,
            method: 'POST'
        })
    }

    const generateP = () => {

        let pass = '';

        let minusculas = "abcdefghijklmnopqrstuvwxyz"
        let mayusculas = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        let numeros = "0123456789"
        let simbolos = "#$!?¡¿.,"

        let str = minusculas + mayusculas + numeros + simbolos

        for (let i = 1; i <= 12; i++) {
            let pos
            let char
            if (i === 2) {
                pos = Math.floor(Math.random() * minusculas.length + 1);
                char = minusculas.charAt(pos)
            } else {

                if (i === 4) {
                    pos = Math.floor(Math.random() * mayusculas.length + 1);
                    char = mayusculas.charAt(pos)
                } else {

                    if (i === 6) {
                        pos = Math.floor(Math.random() * simbolos.length + 1);
                        char = simbolos.charAt(pos)
                    } else {

                        if (i === 8) {
                            pos = Math.floor(Math.random() * numeros.length + 1);
                            char = numeros.charAt(pos)
                        } else {
                            pos = Math.floor(Math.random() * str.length + 1);
                            char = str.charAt(pos)
                        }
                    }
                }
            }

            if (char === "") {
                char = "g"
            }

            pass += char

        }

        setContraseña(pass)
    }
    
    const validarString = (string) => {
        // Verificación de los requisitos
        if (!/[A-Z]/.test(string)) {
            return "La contraseña debe contener al menos una letra mayúscula.";
        }
        if (!/[a-z]/.test(string)) {
            return "La contraseña debe contener al menos una letra minúscula.";
        }
        if (!/\d/.test(string)) {
            return "La contraseña debe contener al menos un número.";
        }
        if (!/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?~-]/.test(string)) {
            return "La contraseña debe contener al menos un símbolo especial.";
        }
    
        // Si cumple con todo
        return 1;
    }

    const saveStudent = () => {

        if(!nombre || !rut || !email || !contraseña){
            Swal.fire({
                icon: 'error',
                text: 'No puede dejar campos en blanco!',
                confirmButtonColor:"#dc3545"
            })
            return
        }

        let res = validarString(contraseña);
        if(res != 1){
            Swal.fire({
                icon: 'error',
                text: res,
                confirmButtonColor:"#dc3545"
            })
            return
        }
        setIsLoading(true)
        let body = {
            name: nombre,
            username: rut,
            email: email,
            password: contraseña,
            profile: rol
        }

        postStudent(body).then((res) => {

            if(body.code == 0){
                Swal.fire({
                    icon: 'success',
                    text: 'Usuario registrado exitosamente!',
                    confirmButtonColor:"#ffc107"
                })
    
                navigate("/")
            }else{

                Swal.fire({
                    icon: 'error',
                    text: "No se pudo crear el usuario",
                    confirmButtonColor:"#ffc107"
                })
            }
            setIsLoading(false)
        })
    }

    return (
        <div className="CreateStudent">
            <h1 className="titulo animate__animated animate__fadeInDown">Creación cuenta estudiante</h1>
            <Row className=" contenedor animate__animated animate__fadeInLeft">

                <div className='mb-5'>
                    <Row className="justify-content-end"></Row>
                </div>

                <Col lg={5} md={6} className='mb-5'>
                    <h3>Nombre</h3>
                    {isLoading ?
                        <Loading />
                        :
                        <FormControl value={nombre} onChange={(event) => setNombre(event.target.value)} style={{ width: "330px" }} disabled={isLoading} />
                    }
                </Col>

                <Col lg={5} md={6} className='mb-5'>
                    <h3>Rut</h3>
                    {isLoading ?
                        <Loading />
                        :
                        <FormControl value={rut} onChange={(event) => setRut(event.target.value.toLowerCase())} style={{ width: "330px" }} disabled={isLoading} />
                    }
                </Col>

                <Col lg={5} md={6} className='mb-5'>
                    <h3>Correo</h3>
                    {isLoading ?
                        <Loading />
                        :
                        <FormControl value={email} onChange={(event) => setEmail(event.target.value)} style={{ width: "330px" }} disabled={isLoading} />
                    }
                </Col>

                <Col lg={5} md={6} className='mb-5'>
                    <h3>Contraseña <Button variant="secondary" onClick={generateP}>Generar contraseña</Button></h3>
                    {isLoading ?
                        <Loading />
                        :
                        <FormControl value={contraseña} onChange={(event) => setContraseña(event.target.value)} style={{ width: "330px" }} disabled={isLoading} />
                    }
                </Col>

                <Col lg={5} md={6}>
                    <h3>Rol</h3>
                    {isLoading ?
                        <Loading />
                        :
                        <>
                            <Form.Select aria-label="Rol" onChange={(event) => {setRol(event.target.value)}} style={{ width: "330px" }} disabled={isLoading}>
                                <option defaultChecked value="STUDENT">Estudiante</option>
                                <option value="TEACHER">Profesor</option>
                                <option value="ASSISTANT">Ayudante</option>
                            </Form.Select>
                        </>
                    }
                </Col>

                <div className='mb-5'>
                    <Row className="justify-content-end">
                        <Col xs={3} sm={2} lg={2} xl={2}><Button variant="secondary" onClick={saveStudent} disabled={isLoading} >Guardar</Button></Col>
                        <Col xs={3} sm={2} lg={2} xl={2}><Link className={isLoading ? "not-active btn btn-danger " : "btn btn-danger "} to="/" disabled={isLoading} >Cancelar</Link></Col>
                    </Row>
                </div>
            </Row>
        </div>
    )
}

export default CreateStudent