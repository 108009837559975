import React from "react"
import "./Course.css"
import CourseStudent from "./CourseStudent"
import CourseTeacher from "./CourseTeacher"

const Course = ({ user }) => {


    return (
        <div className="Course">
            {user.rol === "TEACHER" ?
                <CourseTeacher user={user}/>
                :
                user.rol === "STUDENT" ?

                    <CourseStudent user={user}/>
                    :
                    <></>
            }
        </div>
    )
}

export default Course