import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading"
import { faEye} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col} from "react-bootstrap";
import { Api} from "../../AWS/aws"
import listExtension from "../../Assets/Js/listExtension"
import "./CourseStudent.css"

const CourseStudent = ({ user }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [course, setCourse] = useState(false)

    const { id } = useParams()

    const getCourse = (id) => {
        return Api({
            path: `/project/course/${id}`
        })
    }

    useEffect(() => {
        getCourse(id).then(res => {
            if (res.code === 0) {
                setCourse(res.body)
                setIsLoading(false)
            }
        })
    }, [id])

    return (
        <div className="CourseStudent">
            <div className="contenedor animate__animated animate__fadeInLeft">
                {
                    isLoading ?
                        <Loading disbledAnimation />
                        :
                        <>
                            <h1 className="titulo animate__animated animate__fadeInDown mt-3 mb-5">{course.name}</h1>
                            <div style={{ paddingLeft: "10%", paddingRight:"10%" }} className="mb-5">
                                {
                                    course.description !== "" &&
                                    <div className="text-editor"  dangerouslySetInnerHTML={{ __html: course.description }}></div>
                                }

                            </div>

                            <div style={{ paddingLeft: "10%"}}>
                                {
                                    course.files.length > 0 &&
                                    <div>
                                        <Row>
                                            {course.files.map((data, index) => {
                                                let arr = data.split("/")
                                                let name = arr[arr.length - 1]
                                                arr = name.split(".")
                                                let extension = arr[arr.length - 1]
                                                let posExtension = listExtension.indexOf(extension)
                                                if (posExtension < 0) {
                                                    extension = "archivo"
                                                }
                                                return (
                                                    <Col key={"col " + index} className="col-file">
                                                        <Row className="card-file">
                                                            <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    className="col-view"
                                                                    icon={faEye}
                                                                />
                                                            </a>

                                                            <Col className="col-img">
                                                                <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                            </Col>

                                                            <Col className="col-name">{name}</Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                }
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default CourseStudent