import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Api } from "../../AWS/aws"
import { Row, Col, Form } from "react-bootstrap"
import Loading from "../../Components/Loading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "./TeamStudent.css"
import { faUsers } from "@fortawesome/free-solid-svg-icons"
import Swal from 'sweetalert2'

const TeamStudent = ({ user }) => {

    const [isLoadingGroups, setIsLoadingGroups] = useState(true)
    const [isLoadingCourse, setIsLoadingCourse] = useState(true)
    const [isEditName, setIsEditName] = useState(false)
    const [group, setGroup] = useState([])
    const [groups, setGroups] = useState([])
    const [course, setCourse] = useState([])
    const [teacher, setTeacher] = useState({})
    const [nameGroup, setNameGroup] = useState(false)
    const [newNameGroup, setNewNameGroup] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const { id } = useParams()

    const navigate = useNavigate()

    const getStudents = (ipProject) => {
        return Api({
            path: `/student/course/${ipProject}`
        })
    }

    const getGroups = (ipProject) => {
        return Api({
            path: `/student/groups/${ipProject}`
        })
    }

    const postGroupName = (body) => {
        return Api({
            path: `/student/group`,
            body: body,
            method: 'POST'
        })
    }

    useEffect(() => {
        getGroups(id).then(res => {
            if (res.code === 0) {
                setIsLoadingGroups(false)
                setGroup(res.body.myGroup.students)

                let arr = res.body.groups.sort(function (b, a) {
                    if (a.name > b.name) {
                        return -1;
                    }
                    if (b.name > a.name) {
                        return 1;
                    }
                    return 0;

                });

                setGroups(arr)
                setNameGroup(res.body.myGroup.name)
            } else {
                setIsLoadingGroups(false)
            }
        })
        getStudents(id).then(res => {
            if (res.code === 0) {
                setIsLoadingCourse(false)
                setCourse(res.body.course)
                setTeacher(res.body.teacher)
            } else {
                setIsLoadingCourse(false)
            }
        })
    }, [])


    return (
        <div className="TeamStudent">
            <Row>
                <Col className="team-info">
                    <div className="container-info">
                        <div style={{ position: "relative" }}>
                            {isEditName ?
                                <h1 className="title">
                                    {
                                        isSaving ?
                                            <Loading disabledAnimation />
                                            :
                                            <Form.Control className="changeName" value={newNameGroup} onChange={(e) => { setNewNameGroup(e.target.value) }} />
                                    }
                                </h1>
                                :
                                <h1 className="title">Mi grupo {nameGroup ? "(" + nameGroup + ")" : ""}</h1>
                            }
                            {nameGroup ?
                                <div className="edit">
                                    {isEditName ?
                                        <FontAwesomeIcon
                                            icon={faSave}
                                            onClick={() => {
                                                if (!isSaving) {
                                                    setIsSaving(true)
                                                    let body = {
                                                        idProyect: id,
                                                        newName: newNameGroup
                                                    }
                                                    postGroupName(body).then(res => {
                                                        if (res.code === 0) {
                                                            Swal.fire({
                                                                icon: 'success',
                                                                text: 'Cambio de nombre exitoso!',
                                                                confirmButtonColor: "#ffc107"
                                                            })
                                                            setNameGroup(newNameGroup)
                                                            setIsEditName(false)
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            onClick={() => {
                                                setNewNameGroup(nameGroup)
                                                setIsEditName(true)
                                            }}
                                        />
                                    }
                                </div>
                                :
                                <></>
                            }

                        </div>

                        <Row className="row-center">
                            {isLoadingGroups ?
                                <Loading disabledAnimation />
                                :
                                group.map((data, index) => {
                                    let arr = data.name.split(" ")
                                    let name = arr[0] + " " + arr[arr.length - 2] + " " + arr[arr.length - 1][0] + "."
                                    return (
                                        <Col key={"col " + index} className="col-group profile">
                                            <div className="text-center" onClick={() => { navigate("/profile/" + data.id) }}>
                                                {data.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                                <div className="col-group-student text-center">
                                                    <p>{name}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>

                    <div className="container-info">
                        <h1 className="title">Otros grupos</h1>
                        <Row className="row-center">
                            {isLoadingGroups ?
                                <Loading disabledAnimation />
                                :
                                groups.map((data, index) => {
                                    return (
                                        <Col key={"col " + index} className="col-group">
                                            <div className="text-center">
                                                {data.urlImg ? "/foto/" : <FontAwesomeIcon icon={faUsers} style={{ fontSize: "40px" }} />}
                                                <div className="group-name">
                                                    <p>{data.name}</p>
                                                </div>
                                                <div className="col-group-student">
                                                    {data.students.map((student, index) => {
                                                        let arr = student.name.split(" ")
                                                        let name = arr[0] + " " + arr[arr.length - 2]
                                                        return (
                                                            <p className="profile" style={{ borderRadius: "6px" }} onClick={() => { navigate("/profile/" + student.id) }} key={"p " + index}>{student.urlImg ? <img width={17} height={17} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + student.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} />} {name}</p>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Col>

                <Col className="project-info">

                    <div className="container-info">
                        <h1 className="title">Profesor</h1>
                        {isLoadingCourse ?
                            <Loading disabledAnimation />
                            :
                            <p className="profile" style={{ borderRadius: "6px" }} onClick={() => { navigate("/profile/" + teacher.id) }}>{teacher.urlImg ? <img width={17} height={17} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + teacher.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} />} {teacher.name}</p>
                        }
                    </div>

                    <div className="container-info">
                        <h1 className="title">Mi Curso</h1>
                        {isLoadingCourse ?
                            <Loading disabledAnimation />
                            :
                            course.length === 0 ?
                                <p>No existen mas participantes de este curso</p>
                                :
                                course.map((student, index) => (
                                    <p className="profile" style={{ borderRadius: "6px" }} onClick={() => { navigate("/profile/" + student.id) }} key={"p " + index}>{student.urlImg ? <img width={17} height={17} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + student.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} />} {student.name}</p>
                                ))
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default TeamStudent