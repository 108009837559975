import React, { useEffect, useState } from "react"
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { authUser, Api } from "./AWS/aws"
import ValidateLogin from "./Components/ValidateLogin"
import NotFound from "./Pages/NotFound/NotFound"
import Login from "./Pages/Login/Login"
import Logout from "./Pages/Logout/Logout"
import Home from "./Pages/Home/Home"
import MyProjects from "./Pages/MyProjects/MyProjects"
import Project from "./Pages/Project/Project"
import Brief from "./Pages/Brief/Brief"
import Team from "./Pages/Team/Team"
import Moodboard from "./Pages/Moodboard/Moodboard"
import Advance from "./Pages/Advance/Advance"
import Submission from "./Pages/Submission/Submission"
import CreateProject from "./Pages/CreateProject/CreateProject"
import CreateCourse from "./Pages/CreateCourse/CreateCourse"
import CreateStudent from "./Pages/CreateStudent/CreateStudent"
import SubmitAdvance from "./Pages/Advance/SubmitAdvance"
import SubmitSubmission from "./Pages/Submission/SubmitSubmission"
import ModalInfo from "./Pages/ModalInfo/ModalInfo"
import Profile from "./Pages/Profile/Profile"
import Course from "./Pages/Course/Course"
import Resume from "./Pages/Resume/Resume"

const ListRoutes = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [user, setUser] = useState({})

    const getUser = () => {
        return Api({
            path: "/user"
        })
    }

    useEffect(() => {
        authUser().then(data => {
            if (data) {
                setIsAuthenticated(true)
                if (isAuthenticating) {
                    getUser().then((data => {
                        setIsAuthenticating(false)
                        if (data.code === 0) {
                            setUser(data.body)
                        }
                    }))
                }
            }
        }).catch(err => {
            setIsAuthenticating(false)
        })
    }, [])

    const auth = { 
        isAuthenticated: isAuthenticated, 
        isAuthenticating: isAuthenticating, 
        user: user 
    }


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/" element={<ValidateLogin auth={auth} permission={[]} to={<Home user={user} />} />}>
                    <Route path="" element={<ValidateLogin auth={auth} permission={[]} to={<MyProjects user={user} />} />} />
                    <Route path="profile" element={<ValidateLogin auth={auth} permission={[]} to={<Profile user={user} />} />} />
                    <Route path="profile/:id" element={<ValidateLogin auth={auth} permission={[]} to={<Profile user={user} />} />} />
                    <Route path="createproject" element={<ValidateLogin auth={auth} permission={["TEACHER"]} to={<CreateProject user={user} />} />} />
                    <Route path="createcourse" element={<ValidateLogin auth={auth} permission={["TEACHER"]} to={<CreateCourse user={user} />} />} />
                    <Route path="createstudent" element={<ValidateLogin auth={auth} permission={["TEACHER"]} to={<CreateStudent user={user} />} />} />
                    <Route path="course/:id" element={<ValidateLogin auth={auth} permission={[]} to={<Course user={user} />} />} />
                    <Route path="project/:id/" element={<ValidateLogin auth={auth} permission={[]} to={<Project user={user} />} />}>
                        <Route path="" element={<ValidateLogin auth={auth} permission={[]} to={<Brief user={user} />} />} />
                        <Route path="team" element={<ValidateLogin auth={auth} permission={[]} to={<Team user={user} />} />} />
                        <Route path="moodboard" element={<ValidateLogin auth={auth} permission={[]} to={<Moodboard user={user} />} />} />
                        <Route path="advance" element={<ValidateLogin auth={auth} permission={[]} to={<Advance user={user} />} />}>
                            <Route path="submit" element={<ValidateLogin auth={auth} permission={["STUDENT"]} to={<SubmitAdvance user={user} />} />} />
                            <Route path=":idAdvance" element={<ValidateLogin auth={auth} permission={[]} to={<ModalInfo from="Avance" user={user}/>} />} />
                        </Route>
                        <Route path="submission" element={<ValidateLogin auth={auth} permission={[]} to={<Submission user={user} />} />} >
                            <Route path="submit" element={<ValidateLogin auth={auth} permission={["STUDENT"]} to={<SubmitSubmission user={user} />} />} />
                            <Route path=":idSubmission" element={<ValidateLogin auth={auth} permission={[]} to={<ModalInfo from="Entrega" user={user}/>} />} />
                        </Route>
                        <Route path="resume" element={<ValidateLogin auth={auth} permission={["TEACHER"]} to={<Resume user={user} />} />} />
                    </Route>
                    <Route path="*" element={<ValidateLogin auth={auth} permission={[]} to={<NotFound  user={user}/>} />} />
                </Route>
                <Route path="*" element={<ValidateLogin auth={auth} permission={[]} to={<NotFound user={user}/>} />} />
            </Routes>
        </BrowserRouter>
    )
}
export default ListRoutes