export default function dateFormat(type, data) {
    if (type === "date") {
        let arr = data.split("T")
        arr = arr[0].split("-")
        return arr[2] + "/" + arr[1] + "/" + arr[0]
    }
    if (type === "dateTime") {
        let arr = data.split("T")
        let date = arr[0].split("-")
        let time = arr[1].split("Z")
        time = time[0].split(":")
        return date[2] + "/" + date[1] + "/" + date[0] + " " + time[0] + ":" + time[1] + ":"+time[2]
    }
    if (type === "timeDate") {
        let arr = data.split("T")
        let date = arr[0].split("-")
        let time = arr[1].split("Z")
        time = time[0].split(":")
        return time[0] + ":" + time[1] + ":"+time[2] + " " +  date[2] + "/" + date[1] + "/" + date[0]
    }
}