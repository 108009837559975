import { logout } from "../../AWS/aws"
import "./Logout.css"

const Logout = () => {
    
    logout()
    window.location = "/"

}

export default Logout