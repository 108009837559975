import React from "react"
import { Navigate } from 'react-router-dom'
import NotFound from "../Pages/NotFound/NotFound"
import Loading from './Loading'

const ValidateLogin = ({ to, auth, permission }) => {
    if (auth) {
        if (auth.isAuthenticating) {
            return <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}>
                <Loading />
            </div>
        } else {
            if (auth.isAuthenticated) {
                let isEnabled = false
                if(permission.length === 0){
                    isEnabled = true
                }else{
                    permission.forEach(element => {
                        if(element === auth.user.rol){
                            isEnabled = true
                        }
                    });
                }
                
                if (isEnabled) {
                    return to
                } else {
                    return <NotFound user={auth.user}/>
                }
            } else {
                return <Navigate to="/login" />
            }
        }
    } else {
        return <Navigate to="/login" />
    }
}

export default ValidateLogin