import React from "react"
import "./Footer.css"

const Footer = () => {

    return (
        <div className="Footer">
            <p><a href="/" target="_blank" rel="noopener noreferrer"><b>Taller Diseño</b></a> Universidad del Bío-Bío / Fondo Desarrollo de la Docencia FDD 2019-1 / Programa Doctorado en Proyectos Universidad Internacional Iberoamericana UNINI.</p>
        </div>
    )
}

export default Footer