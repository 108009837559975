import React from "react"
import TeamStudent from "./TeamStudent"
import TeamTeacher from "./TeamTeacher"
import "./Team.css"

const Team = ({ user }) => {

    return (
        <div className="Team">
            {user.rol === "STUDENT" ?
                <TeamStudent user={user} />
                :
                <TeamTeacher user={user} />
            }
        </div>
    )
}

export default Team