import React from "react";
import { StyleSheet, Document, Page, Text, View, Image } from "@react-pdf/renderer";
import logo2 from "../Assets/Img/logo2.png"
import dateFormat from "../Assets/Js/dateFormat";

const PDF = ({ data }) => {
    data.groups = data.groups.sort(function (a, b) { return a.name.localeCompare(b.name) });
    for (let i = 0; i < data.groups.length; i++) {
        data.groups[i].students = data.groups[i].students.sort(function (a, b) { return a.name.localeCompare(b.name) });
    }

    if (data.submission) {
        data.submission = data.submission.sort(function (a, b) { return a.num - b.num });
    }
    if (data.advance) {
        data.advance = data.advance.sort(function (a, b) { return a.num - b.num });
    }

    let mayor = 0
    if (mayor < data.comentarios) {
        mayor = data.comentarios
    }
    if (mayor < data.moodboard) {
        mayor = data.moodboard
    }
    if (mayor < data.advances) {
        mayor = data.advances
    }
    if (mayor < data.submissions) {
        mayor = data.submissions
    }

    return (
        <Document title={"Resumen " + data.name} >

            <Page size={"FOLIO"} >
                <Header fecha={dateFormat("timeDate", data.dateResume)} />
                <View style={styles.title}>
                    <Text>{data.name}</Text>
                </View>
                <View style={styles.todo}>
                    <Text>Fecha de creación proyecto:</Text>
                    <View style={styles.info}>
                        <Text>{dateFormat("timeDate", data.date)}</Text>
                    </View>
                    <Text>Curso:</Text>
                    <View style={styles.info}>
                        <Text>{data.course.name}</Text>
                    </View>
                    <Text>Profesor:</Text>
                    <View style={styles.info}>
                        <Text>{data.teacher.name}</Text>
                    </View>
                    {
                        data.assistant &&
                        <>
                            <Text>Ayudante:</Text>
                            <View style={styles.info}>
                                <Text>{data.assistant.name}</Text>
                            </View>
                        </>

                    }
                    <Text>Cantidad de grupos:</Text>
                    <View style={styles.info}>
                        <Text>{data.groups.length}</Text>
                    </View>

                    {
                        data.advance &&
                        data.advance.map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Text>Fecha avance {res.num}</Text>
                                    <View style={styles.info}>
                                        <Text>{dateFormat("timeDate", res.endDate)}</Text>
                                    </View>
                                </React.Fragment>
                            )
                        })
                    }

                    {
                        data.submission &&
                        data.submission.map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Text>Fecha entrega {res.num}</Text>
                                    <View style={styles.info}>
                                        <Text>{dateFormat("timeDate", res.endDate)}</Text>
                                    </View>
                                </React.Fragment>
                            )
                        })
                    }



                </View>
            </Page>

            <Page size={"FOLIO"}>
                <Header />

                <View style={styles.subTitle}>
                    <Text>Acciones totales realizadas</Text>
                </View>
                <View style={styles.todo}>
                    <View style={styles.grafico}>
                        <View style={
                            {
                                width: mayor !== 0 ? data.comentarios !== 0 ? ((data.comentarios * 100) / mayor) + "%" : "0%" : "0%",
                                height: "50px",
                                backgroundColor: "rgb(254, 200, 107)",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                position: "relative"
                            }
                        }>
                            <View style={{
                                position: "absolute",
                                left: "-60px",
                                transform: "rotate(-90deg)",
                                fontSize: "13px",
                                top: "18px",
                                width: "100px",
                                textAlign: "center"

                            }}>
                                <Text>Comentarios</Text>
                            </View>
                            <View style={{
                                position: "absolute",
                                top: "15px",
                                right: "-25px"
                            }}>
                                <Text>{data.comentarios}</Text>
                            </View>
                        </View>
                        <View style={
                            {
                                width: mayor !== 0 ? data.moodboard !== 0 ? ((data.moodboard * 100) / mayor) + "%" : "0%" : "0%",
                                height: "50px",
                                backgroundColor: "rgb(119, 114, 105)",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                position: "relative"
                            }
                        }>
                            <View style={{
                                position: "absolute",
                                left: "-60px",
                                transform: "rotate(-90deg)",
                                fontSize: "13px",
                                top: "18px",
                                width: "100px",
                                textAlign: "center"

                            }}>
                                <Text>Moodboards</Text>
                            </View>
                            <View style={{
                                position: "absolute",
                                top: "15px",
                                right: "-25px"
                            }}>
                                <Text>{data.moodboard}</Text>
                            </View>
                        </View>
                        {
                            data.advance &&
                            <View style={
                                {
                                    width: mayor !== 0 ? data.advances !== 0 ? ((data.advances * 100) / mayor) + "%" : "0%" : "0%",
                                    height: "50px",
                                    backgroundColor: "rgb(207, 67, 67)",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    position: "relative"
                                }
                            }>
                                <View style={{
                                    position: "absolute",
                                    left: "-60px",
                                    transform: "rotate(-90deg)",
                                    fontSize: "13px",
                                    top: "18px",
                                    width: "100px",
                                    textAlign: "center"

                                }}>
                                    <Text>Avances</Text>
                                </View>
                                <View style={{
                                    position: "absolute",
                                    top: "15px",
                                    right: "-25px"
                                }}>
                                    <Text>{data.advances}</Text>
                                </View>
                            </View>
                        }

                        <View style={
                            {
                                width: mayor !== 0 ? data.submissions !== 0 ? ((data.submissions * 100) / mayor) + "%" : "0%" : "0%",
                                height: "50px",
                                backgroundColor: "rgb(67, 207, 67)",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                position: "relative"
                            }
                        }>
                            <View style={{
                                position: "absolute",
                                left: "-60px",
                                transform: "rotate(-90deg)",
                                fontSize: "13px",
                                top: "18px",
                                width: "100px",
                                textAlign: "center"

                            }}>
                                <Text>Entregas</Text>
                            </View>
                            <View style={{
                                position: "absolute",
                                top: "15px",
                                right: "-25px"
                            }}>
                                <Text>{data.submissions}</Text>
                            </View>
                        </View>
                    </View>
                </View>

            </Page>

            {
                data.groups.map((grupo, indexGrupo) => {
                    return (
                        <React.Fragment key={indexGrupo}>
                            {
                                grupo.students.map((student, index) => {

                                    return (
                                        <Page size={"FOLIO"} key={index}>
                                            <Header />
                                            <View style={styles.subTitle}>
                                                <Text>{grupo.name}</Text>
                                            </View>
                                            <View style={styles.subTitle2}>
                                                <Text>{student.name}</Text>
                                            </View>
                                            <View style={styles.todo}>
                                                <Text>Rut:</Text>
                                                <View style={styles.info}>
                                                    <Text>{student.id}</Text>
                                                </View>
                                                <Text>Correo:</Text>
                                                <View style={styles.info}>
                                                    <Text>{student.email}</Text>
                                                </View>

                                                <Text>Visitas del Moodboard:</Text>
                                                <View style={styles.info}>
                                                    <Text>{student.viewMyMoodboard}</Text>
                                                </View>

                                                <Text>Acciones realizadas:</Text>
                                                <View style={styles.grafico}>
                                                    <View style={
                                                        {
                                                            width: data.mayor !== 0 ? student.comentary !== 0 ? ((student.comentary * 100) / data.mayor) + "%" : "0%" : "0%",
                                                            height: "50px",
                                                            backgroundColor: "rgb(254, 200, 107)",
                                                            borderTopRightRadius: "10px",
                                                            borderBottomRightRadius: "10px",
                                                            position: "relative"
                                                        }
                                                    }>
                                                        <View style={{
                                                            position: "absolute",
                                                            left: "-60px",
                                                            transform: "rotate(-90deg)",
                                                            fontSize: "13px",
                                                            top: "18px",
                                                            width: "100px",
                                                            textAlign: "center"

                                                        }}>
                                                            <Text>Comentarios</Text>
                                                        </View>
                                                        <View style={{
                                                            position: "absolute",
                                                            top: "15px",
                                                            right: "-20px"
                                                        }}>
                                                            <Text>{student.comentary}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={
                                                        {
                                                            width: data.mayor !== 0 ? student.viewOtherMoodboard !== 0 ? ((student.viewOtherMoodboard * 100) / data.mayor) + "%" : "0%" : "0%",
                                                            height: "50px",
                                                            backgroundColor: "rgb(119, 114, 105)",
                                                            borderTopRightRadius: "10px",
                                                            borderBottomRightRadius: "10px",
                                                            position: "relative"
                                                        }
                                                    }>
                                                        <View style={{
                                                            position: "absolute",
                                                            left: "-60px",
                                                            transform: "rotate(-90deg)",
                                                            fontSize: "13px",
                                                            top: "18px",
                                                            width: "100px",
                                                            textAlign: "center"

                                                        }}>
                                                            <Text>Moodboards</Text>
                                                        </View>
                                                        <View style={{
                                                            position: "absolute",
                                                            top: "15px",
                                                            right: "-20px"
                                                        }}>
                                                            <Text>{student.viewOtherMoodboard}</Text>
                                                        </View>
                                                    </View>
                                                    {
                                                        data.advance &&
                                                        <View style={
                                                            {
                                                                width: data.mayor !== 0 ? student.viewOtherAdvance !== 0 ? ((student.viewOtherAdvance * 100) / data.mayor) + "%" : "0%" : "0%",
                                                                height: "50px",
                                                                backgroundColor: "rgb(207, 67, 67)",
                                                                borderTopRightRadius: "10px",
                                                                borderBottomRightRadius: "10px",
                                                                position: "relative"
                                                            }
                                                        }>
                                                            <View style={{
                                                                position: "absolute",
                                                                left: "-60px",
                                                                transform: "rotate(-90deg)",
                                                                fontSize: "13px",
                                                                top: "18px",
                                                                width: "100px",
                                                                textAlign: "center"

                                                            }}>
                                                                <Text>Avances</Text>
                                                            </View>
                                                            <View style={{
                                                                position: "absolute",
                                                                top: "15px",
                                                                right: "-20px"
                                                            }}>
                                                                <Text>{student.viewOtherAdvance}</Text>
                                                            </View>
                                                        </View>
                                                    }

                                                    <View style={
                                                        {
                                                            width: data.mayor !== 0 ? student.viewOtherSubmission !== 0 ? ((student.viewOtherSubmission * 100) / data.mayor) + "%" : "0%" : "0%",
                                                            height: "50px",
                                                            backgroundColor: "rgb(67, 207, 67)",
                                                            borderTopRightRadius: "10px",
                                                            borderBottomRightRadius: "10px",
                                                            position: "relative"
                                                        }
                                                    }>
                                                        <View style={{
                                                            position: "absolute",
                                                            left: "-60px",
                                                            transform: "rotate(-90deg)",
                                                            fontSize: "13px",
                                                            top: "18px",
                                                            width: "100px",
                                                            textAlign: "center"

                                                        }}>
                                                            <Text>Entregas</Text>
                                                        </View>
                                                        <View style={{
                                                            position: "absolute",
                                                            top: "15px",
                                                            right: "-20px"
                                                        }}>
                                                            <Text>{student.viewOtherSubmission}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </Page>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                })
            }

        </Document>
    );
};

const Header = ({ fecha }) => {

    return (
        <>
            <View style={styles.row}>
                <View style={{ width: "56%" }}>
                    {
                        fecha &&
                        <View style={styles.fecha}>
                            <Text>Fecha Resumen</Text>
                            <Text>{fecha}</Text>
                        </View>
                    }
                </View>
                <View style={{ width: "44%" }}>
                    <Image style={{ height: "40px", width: "211px", margin: "20px" }} src={logo2}></Image>
                </View>
            </View>

        </>
    );
};

const styles = StyleSheet.create({
    todo: {
        margin: "60px",
        marginTop: "0px",
        fontSize: "15px"
    },
    fecha: {
        marginBottom: "10px",
        marginTop: "10px",
        height: "40px",
        width: "70%",
        textAlign: "center",
        margin: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid back",
        borderRadius: "5px",
        fontSize: "12px"
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    col: {
        flex: "1 0 0%"
    },
    title: {
        textAlign: "center",
        margin: "10px",
        marginBottom: "20px",
        fontSize: "30px",
        fontWeight: "bold"
    },
    subTitle: {
        textAlign: "center",
        margin: "10px",
        fontSize: "25px"
    },
    subTitle2: {
        textAlign: "left",
        margin: "10px",
        marginLeft: "40px",
        fontSize: "20px"
    },
    info: {
        width: "70%",
        padding: "5px",
        paddingLeft: "15px",
        textAlign: "left",
        margin: "10px",
        border: "1px solid back",
        borderRadius: "5px"
    },
    grafico: {
        height: "400px",
        margin: "10px",
        border: "1px solid back",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-evenly"
    }
})

export default PDF;