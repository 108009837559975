import React from "react"
import AdvanceStudent from "./AdvanceStudent"
import AdvanceTeacher from "./AdvanceTeacher"
import "./Advance.css"

const Advance = ({ user }) => {

    return (
        <div className="Advance">
            {user.rol === "STUDENT" ?
                <AdvanceStudent user={user} />
                :
                <AdvanceTeacher user={user} />
            }
        </div>
    )
}

export default Advance