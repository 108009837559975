import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faCircle, faChevronUp, faUser, faChevronLeft, faChevronRight, faChevronDown, faXmark, faPaperPlane, faAnglesUp, faCheckDouble, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Col, Form, Row } from "react-bootstrap"
import { Api, getToken } from "../AWS/aws"
import { useParams } from "react-router-dom"
import notification from "../Assets/Sound/notification.mp3"
import Picker from 'emoji-picker-react';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { faFaceSmile, faImage } from "@fortawesome/free-regular-svg-icons"
import "./Chat.css"

function testJSON(text) {

    if (typeof text !== "string") {
        return false;
    }
    try {
        return JSON.parse(text);
    } catch (error) {
        return false;
    }

}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Chat extends Component {

    constructor(props) {
        super(props);
        this.socket = React.createRef();
        this.state = {
            isLoading: false,
            show: false,
            chats: [],
            users: [],
            notification: new Audio(notification)
        };
    }

    getStudents = (ipProject) => {
        return Api({
            path: `/student/course/${ipProject}`
        })
    }

    onSocketMessage = (data) => {
        let dataObject = JSON.parse(data);
        if (dataObject.type === "markRead") {
            let arrChats = this.state.chats
            let pos = -1
            for (let index = 0; index < arrChats.length; index++) {
                if (arrChats[index].id === dataObject.to) {
                    pos = index
                    break
                }
            }
            if (pos > -1) {
                arrChats[pos].messages.forEach(res => {
                    if (res.status === "UNREAD") {
                        res.status = "READ"
                    }
                })
                this.setState({ chats: arrChats })
            }
        }
        if (dataObject.type === "readMessagePrivate") {
            let arrChats = this.state.chats
            let chat = ""
            if (dataObject.user === this.props.user.id) {
                chat = dataObject.otherUser
            } else {
                chat = dataObject.user
            }


            let pos = -1
            for (let index = 0; index < arrChats.length; index++) {
                if (arrChats[index].id === chat) {
                    pos = index
                    break
                }
            }
            if (pos > -1) {
                let first = false
                if (arrChats[pos].messages.length === 0) {
                    first = true
                }
                dataObject.messages.forEach((res) => {
                    let body = {
                        sendByMe: true,
                        message: res.message,
                        idMessage: res.idMessage,
                        status: res.status,
                        date: res.date
                    };

                    if (res.to === this.props.user.id) {
                        body.sendByMe = false
                    }

                    arrChats[pos].messages.unshift(body)
                })

                if (dataObject.LastEvaluatedKey) {
                    arrChats[pos].LastEvaluatedKey = dataObject.LastEvaluatedKey
                } else {
                    delete arrChats[pos].LastEvaluatedKey
                }

                this.setState({ chats: arrChats })
                if (first) {
                    let chatMessages = document.getElementById("messages " + pos)
                    chatMessages.scrollTop = chatMessages.scrollHeight
                }
            }

        }
        if (dataObject.type === "sendPrivate") {
            let arrChats = this.state.chats
            if (dataObject.to === this.props.user.id) {
                let body = {
                    sendByMe: false,
                    message: dataObject.message,
                    idMessage: dataObject.idMessage,
                    status: "UNREAD",
                    date: dataObject.date
                }
                let pos = -1
                for (let index = 0; index < arrChats.length; index++) {
                    if (arrChats[index].id === dataObject.from) {
                        pos = index
                        break
                    }
                }
                if (pos >= 0) {
                    if (!arrChats[pos].show) {
                        arrChats[pos].unreadMessage = arrChats[pos].unreadMessage + 1
                    } else {
                        body.status = "READ"
                        this.socket.current?.send(JSON.stringify({
                            action: "markRead",
                            user: this.props.user.id,
                            otherUser: arrChats[pos].id,
                            idProject: this.props.params.id
                        }))
                        //marcar visto
                    }
                    this.state.notification.play()
                    arrChats[pos].messages.push(body)
                    this.setState({ chats: arrChats })
                } else {
                    let pos = -1
                    let arr = this.state.users
                    for (let index = 0; index < arr.length; index++) {
                        if (arr[index].id === dataObject.from) {
                            pos = index
                            break
                        }
                    }
                    if (pos >= 0) {

                        this.state.notification.play()
                        arrChats.push({ pos: pos, showPicker: false, show: false, messages: [], id: this.state.users[pos].id, unreadMessage: 1 })
                        this.setState({ chats: arrChats })

                        this.socket.current?.send(JSON.stringify({
                            action: "readMessagePrivate",
                            user: this.props.user.id,
                            otherUser: this.state.users[pos].id,
                            idProject: this.props.params.id
                        }))
                    }
                }
            } else {
                let body = {
                    sendByMe: true,
                    message: dataObject.message,
                    idMessage: dataObject.idMessage,
                    status: "UNREAD",
                    date: dataObject.date
                }
                let pos = -1
                for (let index = 0; index < arrChats.length; index++) {
                    if (arrChats[index].id === dataObject.to) {
                        pos = index
                        break
                    }
                }
                if (pos >= 0) {
                    arrChats[pos].messages.push(body)
                    this.setState({ chats: arrChats })
                    let chatMessages = document.getElementById("messages " + pos)
                    chatMessages.scrollTop = chatMessages.scrollHeight
                }
            }

        }
        if (dataObject.type === "sendGroup" || dataObject.type === "disconnect") {
            let arr = this.state.users
            let newArr = []
            for (let i = 0; i < arr.length; i++) {
                let user = dataObject.members.filter((member) => member.id === arr[i].id)
                if (user.length === 1) {
                    arr[i].online = true
                    newArr.unshift(arr[i])
                } else {
                    arr[i].online = false
                    newArr.push(arr[i])
                }
            }
            this.setState({ users: newArr })
        }
    }

    onSocketOpen = () => {
        this.socket.current?.send(JSON.stringify({
            action: "sendGroup",
            idProject: this.props.params.id,
            user: this.props.user
        }))
    }

    onSocketClose = () => {
        console.log("Desconectado");
    }

    onSendMessage = (index) => {
        let to = this.state.users[this.state.chats[index].pos].id
        let input = document.getElementById("input " + index)
        let message = input.value
        if (this.state.chats[index].uploadImage) {
            let chats = this.state.chats
            message = {
                type: "img",
                url: this.state.chats[index].url,
                message: input.value
            }
            chats[index].uploadImage = false
            chats[index].nameImage = ""
            chats[index].url = ""
            this.setState({ chats: chats })

            input.value = ""
            this.socket.current?.send(JSON.stringify({
                action: "sendPrivate",
                to: to,
                message: JSON.stringify(message),
                idProject: this.props.params.id,
                user: this.props.user
            }))
        } else {
            input.value = ""
            this.socket.current?.send(JSON.stringify({
                action: "sendPrivate",
                to: to,
                message: message,
                idProject: this.props.params.id,
                user: this.props.user
            }))
        }

    }

    componentDidMount() {

        this.getStudents(this.props.params.id).then(async (res) => {
            if (res.code === 0) {
                let arr = res.body.course
                if (this.props.user.rol !== "TEACHER" && Object.values(res.body.teacher).length !== 0) {
                    arr.push(res.body.teacher)
                }
                if (this.props.user.rol !== "ASSISTANT" && Object.values(res.body.assistant).length !== 0) {
                    arr.push(res.body.assistant)
                }

                this.setState({ users: arr })


                const token = await getToken();

                this.socket.current = new WebSocket("wss://1c0kdmwznk.execute-api.us-east-1.amazonaws.com/dev?token=" + token)
                this.socket.current.addEventListener('open', this.onSocketOpen)
                this.socket.current.addEventListener('close', this.onSocketClose)
                this.socket.current.addEventListener('message', (event) => { this.onSocketMessage(event.data) })
            }
        })
        window.addEventListener('resize', (e) => {
            let chats = this.state.chats
            if (window.outerWidth >= 310) {
                //1
                let size = chats.length
                if (size > 1) {
                    let arr = chats
                    arr.splice(0, (size - 1))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 565) {
                //2
                let size = chats.length
                if (size > 2) {
                    let arr = chats
                    arr.splice(0, (size - 2))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 820) {
                //3
                let size = chats.length
                if (size > 3) {
                    let arr = chats
                    arr.splice(0, (size - 13))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 1075) {
                //4
                let size = chats.length
                if (size > 4) {
                    let arr = chats
                    arr.splice(0, (size - 4))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 1330) {
                //5
                let size = chats.length
                if (size > 5) {
                    let arr = chats
                    arr.splice(0, (size - 5))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 1585) {
                //6
                let size = chats.length
                if (size > 6) {
                    let arr = chats
                    arr.splice(0, (size - 6))
                    this.setState({ chats: arr })
                }
            }
            if (window.outerWidth >= 1840) {
                //7
                let size = chats.length
                if (size > 7) {
                    let arr = chats
                    arr.splice(0, (size - 7))
                    this.setState({ chats: arr })
                }
            }
        });
    }

    componentWillUnmount() {
        this.socket.current?.close()
    }

    handleShow = () => {
        this.setState({ show: !this.state.show })
    }

    onClickUser = (index) => {
        let arr = this.state.chats
        let arrAux = arr.filter(data => data.pos === index)
        let pos = arrAux.length
        if (pos === 0) {
            let maxSize = 1
            if (window.outerWidth >= 310) {
                maxSize = 1
            }
            if (window.outerWidth >= 565) {
                maxSize = 2
            }
            if (window.outerWidth >= 820) {
                maxSize = 3
            }
            if (window.outerWidth >= 1075) {
                maxSize = 4
            }
            if (window.outerWidth >= 1330) {
                maxSize = 5
            }
            if (window.outerWidth >= 1585) {
                maxSize = 6
            }
            if (window.outerWidth >= 1840) {
                maxSize = 7
            }
            if (arr.length === maxSize) {
                arr.splice(0, 1)
                arr.push({ pos: index, showPicker: false, show: true, messages: [], id: this.state.users[index].id, unreadMessage: 0 })
                this.setState({ chats: arr })
            } else {
                arr.push({ pos: index, showPicker: false, show: true, messages: [], id: this.state.users[index].id, unreadMessage: 0 })
                this.setState({ chats: arr })
            }
            this.socket.current?.send(JSON.stringify({
                action: "readMessagePrivate",
                user: this.props.user.id,
                otherUser: this.state.users[index].id,
                idProject: this.props.params.id
            }))
        }
    }

    onClickChatUser = (index) => {
        let arr = this.state.chats
        let aux = arr[index]
        aux.show = !aux.show
        if (aux.unreadMessage > 0) {
            aux.unreadMessage = 0
            this.socket.current?.send(JSON.stringify({
                action: "markRead",
                user: this.props.user.id,
                otherUser: arr[index].id,
                idProject: this.props.params.id
            }))
        }
        arr[index] = aux
        this.setState({ chats: arr })
    }

    onDeleteChatUser = (index) => {
        let arr = this.state.chats
        arr.splice(index, 1)
        this.setState({ chats: arr })
    }

    uploadImage = async (key, file) => {
        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGE4A6BCMS",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })

            parallelUploads3.on("httpUploadProgress", (process) => {

            })

            await parallelUploads3.done()
            return true
        } catch (e) {
            return false
        }
    }

    fileChat = (index, e) => {
        if (e.target.files[0]) {
            let chats = this.state.chats
            let key = this.props.user.id + "/chat/" + Date.now() + "/" + e.target.files[0].name
            this.uploadImage(key, e.target.files[0])
            chats[index].uploadImage = true
            chats[index].nameImage = e.target.files[0].name
            chats[index].url = "https://tallervirtualarchivos.s3.amazonaws.com/" + key
            this.setState({ chats: chats })
        }
    }

    render() {
        return (
            <div className="Chat" >
                <div className="chat-open" style={this.state.show ? { right: "300px" } : { right: "0px" }} onClick={this.handleShow}>
                    <FontAwesomeIcon className="icon" icon={faComment} />
                    {this.state.show ?
                        <FontAwesomeIcon className="icon" style={{ marginLeft: "5px" }} icon={faChevronLeft} />
                        :
                        <FontAwesomeIcon className="icon" style={{ marginLeft: "4px" }} icon={faChevronRight} />
                    }
                </div>

                <div className="list-users" style={this.state.show ? { display: "block" } : { display: "none" }}>
                    <div className="my-4 text-center">
                        <h3>Lista de usuarios</h3>
                    </div>

                    <div>
                        {this.state.users.map((data, index) => {
                            let arr = data.name.split(" ")
                            let name = ""
                            if(arr[arr.length - 2]){
                                name = arr[0] + " " + arr[arr.length - 2]
                            }else{
                                name = arr[0]
                            }
                            return (
                                <Row key={index} style={{ height: "40px" }} className="px-3 my-3 list-user" onClick={this.onClickUser.bind(this, index)}>
                                    <Col className="user-open" style={{ color: "rgb(var(--color-7))" }}>

                                        {data.urlImg ? <img width={17} height={17} style={{ objectFit: "cover", borderRadius: "40%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.urlImg} alt="foto perfil" /> : <FontAwesomeIcon className="user-open-icon" icon={faUser} />}

                                    </Col>

                                    <Col className="user-name" style={{ fontSize: "20px" }}>
                                        <span>{name}</span>
                                    </Col>

                                    <Col className="user-status">

                                        {data.online ?
                                            <FontAwesomeIcon icon={faCircle} />
                                            :
                                            <></>
                                        }
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>

                </div>

                {this.state.chats.map((chat, index) => {
                    let data = this.state.users[chat.pos]
                    let arr = data.name.split(" ")
                    let name = ""
                    if(arr[arr.length - 2]){
                        name = arr[0] + " " + arr[arr.length - 2]
                    }else{
                        name = arr[0]
                    }
                
                    if (chat.unreadMessage > 0 && !chat.show) {

                    }

                    if (chat.unreadMessage > 0 && chat.show) {
                        console.log("mensajes leidos");
                    }

                    return (
                        <div key={"user" + index}>
                            <div className="user" style={this.state.show ? chat.show ? { right: (355 + (255 * index)) + "px", bottom: "325px" } : { right: (355 + (255 * index)) + "px" } : chat.show ? { right: (55 + (255 * index)) + "px", bottom: "325px" } : { right: (55 + (255 * index)) + "px" }}>
                                <Row style={{ height: "100%" }}>
                                    <Col className="user-status">
                                        {data.online ?
                                            <FontAwesomeIcon icon={faCircle} />
                                            :
                                            <></>
                                        }
                                    </Col>

                                    <Col className="user-name">
                                        {chat.unreadMessage > 0 ? <p>{name + "(" + chat.unreadMessage + ")"}</p> : <p>{name}</p>}

                                    </Col>

                                    <Col className="user-open" onClick={this.onClickChatUser.bind(this, index)}>
                                        {chat.show ?
                                            <FontAwesomeIcon className="user-open-icon" icon={faChevronDown} />
                                            :
                                            <FontAwesomeIcon className="user-open-icon" icon={faChevronUp} />}
                                    </Col>

                                    <Col className="user-open" onClick={this.onDeleteChatUser.bind(this, index)}>
                                        <FontAwesomeIcon className="user-open-icon" icon={faXmark} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="chat" style={this.state.show ? chat.show ? { right: (355 + (255 * index)) + "px" } : { right: (355 + (255 * index)) + "px", display: "none" } : chat.show ? { right: (55 + (255 * index)) + "px" } : { right: (55 + (255 * index)) + "px", display: "none" }}>
                                <Row style={{ height: "calc(100% - 35px)" }} className="list-menssages" id={"messages " + index}>
                                    <Col>
                                        {chat.LastEvaluatedKey &&
                                            <div className="d-flex justify-content-center">
                                                <p className="user-open-icon" onClick={() => {
                                                    this.socket.current?.send(JSON.stringify({
                                                        action: "readMessagePrivate",
                                                        user: this.props.user.id,
                                                        otherUser: this.state.users[chat.pos].id,
                                                        idProject: this.props.params.id,
                                                        LastEvaluatedKey: chat.LastEvaluatedKey
                                                    }))
                                                }}
                                                > -----<FontAwesomeIcon icon={faAnglesUp} />----- </p>
                                            </div>
                                        }
                                        {chat.messages.map((res, index) => {
                                            let isJSON = false
                                            let isImg = false
                                            let obj = testJSON(res.message)
                                            if (obj) {
                                                if (obj.type) {
                                                    if (obj.type === "img") {

                                                        isJSON = true

                                                        if (obj.type === "img") {
                                                            isImg = true
                                                        }
                                                    }
                                                }

                                            }

                                            if (res.sendByMe) {
                                                return (
                                                    <div key={"chat" + index} className="message justify-content-end">
                                                        {
                                                            isJSON ?
                                                                <p style={{ backgroundColor: "rgb(var(--color-2))", paddingBottom: "20px" }}>

                                                                    {isImg ?
                                                                        <>
                                                                            <img width={"180px"} style={{ borderRadius: "5px" }} src={obj.url} />
                                                                            <br />
                                                                            {obj.message}
                                                                        </>
                                                                        :
                                                                        "*Archivo*"
                                                                    }

                                                                    {res.status === "UNREAD" ?
                                                                        <label className="status"><FontAwesomeIcon icon={faCheck} /></label>
                                                                        :
                                                                        <label className="status"><FontAwesomeIcon icon={faCheckDouble} /></label>
                                                                    }

                                                                </p>
                                                                :
                                                                <p style={{ backgroundColor: "rgb(var(--color-2))", paddingBottom: "20px" }}>
                                                                    {res.message}
                                                                    {res.status === "UNREAD" ?
                                                                        <label className="status"><FontAwesomeIcon icon={faCheck} /></label>
                                                                        :
                                                                        <label className="status"><FontAwesomeIcon icon={faCheckDouble} /></label>
                                                                    }

                                                                </p>
                                                        }


                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={"chat" + index} className="message">
                                                        {
                                                            isJSON ?
                                                                <p style={{ backgroundColor: "rgb(var(--color-10))" }}>
                                                                    {isImg ?
                                                                        <>
                                                                            <img width={"180px"} style={{ borderRadius: "5px" }} src={obj.url} />
                                                                            <br />
                                                                            {obj.message}
                                                                        </>
                                                                        :
                                                                        "*Archivo*"
                                                                    }
                                                                </p>
                                                                :
                                                                <p style={{ backgroundColor: "rgb(var(--color-10))" }}>{res.message}</p>
                                                        }

                                                    </div>
                                                )
                                            }
                                        })}
                                    </Col>
                                </Row>
                                <Row style={{ height: "35px" }} >

                                    <Col className="">
                                        <Row style={{ marginLeft: "5px" }}>
                                            <Col style={{ padding: "2px", paddingRight: "0px" }}>
                                                <div style={{ position: "relative" }}>

                                                    {
                                                        chat.uploadImage ?
                                                            <div className="name-image-upload">
                                                                <span>{chat.nameImage}</span>
                                                            </div>
                                                            :
                                                            <></>
                                                    }

                                                    <Form.Control style={{ paddingRight: "40px" }} as="textarea" rows={1} autoComplete="off" size="sm" id={"input " + index} onKeyDown={(e) => {
                                                        if (e.code === "Enter" || e.which === 13) {
                                                            this.onSendMessage(index)
                                                        }
                                                    }} />
                                                    {
                                                        chat.showPicker ?
                                                            <div style={{ position: "absolute", top: "-325px", right: "0px", zIndex: "5000" }}>
                                                                <Picker onEmojiClick={(event, emojiObject) => {
                                                                    let input = document.getElementById("input " + index)
                                                                    input.value = input.value + emojiObject.emoji
                                                                }} />
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    <FontAwesomeIcon style={{ color: "rgb(var(--color-22))", cursor: "pointer", position: "absolute", top: "8px", right: "6px" }} icon={faFaceSmile} onClick={() => {
                                                        let arrChats = this.state.chats
                                                        arrChats[index].showPicker = !arrChats[index].showPicker
                                                        this.setState({ chats: arrChats })
                                                    }} />

                                                    <input
                                                        id={"inputImgChat" + index}
                                                        type="file"
                                                        accept="image/*"
                                                        hidden
                                                        value={""}
                                                        onChange={this.fileChat.bind(this, index)}
                                                    />

                                                    <FontAwesomeIcon style={{ color: "rgb(var(--color-22))", cursor: "pointer", position: "absolute", top: "8px", right: "24px" }} icon={faImage} onClick={() => {
                                                        let input = document.getElementById("inputImgChat" + index)
                                                        input.click()
                                                    }} />
                                                </div>
                                            </Col>
                                            <Col style={{ flex: " 0 0 auto", width: "40px", marginTop: "6px" }} className="text-center">
                                                <FontAwesomeIcon className="user-open-icon" icon={faPaperPlane} onClick={this.onSendMessage.bind(this, index)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                })}
            </div >
        )
    }
}
export default withParams(Chat);