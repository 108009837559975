import React from "react"
import { useState, Component } from "react"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom";
import Loading from "../../Components/Loading"
import imgDefault from "../../Assets/Img/default.png"
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { Row, Col, Button, FormControl } from "react-bootstrap";
import { Api, changePassword } from "../../AWS/aws"
import listExtension from "../../Assets/Js/listExtension"
import "./CourseTeacher.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class CourseTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            course: false,
            isEdit: false
        };
    }

    getCourse = (id) => {
        return Api({
            path: `/project/course/${id}`
        })
    }

    componentDidMount() {
        let id = this.props.params.id
        this.getCourse(id).then(res => {
            if (res.code === 0) {
                this.setState({course: res.body, isLoading:false})
            }
        })
    }

    render() {

        let course = this.state.course
        let isLoading = this.state.isLoading

        return (
            <div className="CourseTeacher">
                <div className="contenedor animate__animated animate__fadeInLeft">
                    {
                        isLoading ?
                            <Loading disbledAnimation />
                            :
                            <>
                                <h1 className="titulo animate__animated animate__fadeInDown mt-3 mb-5">{course.name}</h1>
                                <div style={{ paddingLeft: "10%", paddingRight:"10%" }} className="mb-5">
                                    {
                                        course.description !== "" &&
                                        <div className="text-editor"  dangerouslySetInnerHTML={{ __html: course.description }}></div>
                                    }

                                </div>

                                <div style={{ paddingLeft: "10%" }}>
                                    {
                                        course.files.length > 0 &&
                                        <div>
                                            <Row>
                                                {course.files.map((data, index) => {
                                                    let arr = data.split("/")
                                                    let name = arr[arr.length - 1]
                                                    arr = name.split(".")
                                                    let extension = arr[arr.length - 1]
                                                    let posExtension = listExtension.indexOf(extension)
                                                    if (posExtension < 0) {
                                                        extension = "archivo"
                                                    }
                                                    return (
                                                        <Col key={"col " + index} className="col-file">
                                                            <Row className="card-file">
                                                                <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon
                                                                        className="col-view"
                                                                        icon={faEye}
                                                                    />
                                                                </a>

                                                                <Col className="col-img">
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                </Col>

                                                                <Col className="col-name">{name}</Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
        )
    }

}
export default withParams(CourseTeacher);