import React from "react"
import "./MyProjects.css"
import MyProjectsAssistant from "./MyProjectsAssistant"
import MyProjectsStudent from "./MyProjectsStudent"
import MyProjectsTeacher from "./MyProjectsTeacher"

const MyProjects = ({ user }) => {


    return (
        <div className="MyProjects">
            {user.rol === "TEACHER" ?
                <MyProjectsTeacher user={user} />
                :
                user.rol === "STUDENT" ?

                    <MyProjectsStudent user={user} />
                    :
                    user.rol === "ASSISTANT" ?

                        <MyProjectsAssistant user={user} />
                        :
                        <></>
            }
        </div>
    )
}

export default MyProjects