import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Api } from "../AWS/aws"
import "./AddFav.css";
import Loading from "./Loading";
import Swal from 'sweetalert2'

export default class AddFav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            id: this.props.id,
            from: this.props.from,
            idProject: this.props.idProject,
            fav: this.props.fav
        };
    }

    postFavoriteAdvance(body) {
        return Api({
            path: `/student/advances/favorite`,
            method: 'POST',
            body: body
        })
    }

    deleteMyFavoriteAdvances(id) {
        return Api({
            path: `/student/advances/favorite/${id}`,
            method: 'DELETE'
        })
    }

    postFavoriteMoodboard(body) {
        return Api({
            path: `/student/moodboard/favorite`,
            method: 'POST',
            body: body
        })
    }

    deleteMyFavoriteMoodboard(id) {
        return Api({
            path: `/student/moodboard/favorite/${id}`,
            method: 'DELETE'
        })
    }

    componentDidMount() {
    }

    onClick = () => {
        this.setState({ isLoading: true })
        if (this.props.fav) {
            if (this.state.from === "Advance") {
                this.deleteMyFavoriteAdvances(this.props.id).then((res) => {
                    this.setState({ isLoading: false, fav: false })
                    Swal.fire({
                        icon: 'success',
                        text: 'Avance quitado de favoritos exitosamente!',
                        confirmButtonColor:"#ffc107"
                    }).then(()=>{
                        window.location = `/project/${this.props.idProject}/advance`
                    })
                    
                })
            } else {
                this.deleteMyFavoriteMoodboard(this.props.id).then((res) => {
                    this.setState({ isLoading: false, fav: false })
                    Swal.fire({
                        icon: 'success',
                        text: 'Moodboard quitado de favoritos exitosamente!',
                        confirmButtonColor:"#ffc107"
                    }).then(()=>{
                        window.location = `/project/${this.props.idProject}/moodboard`
                    })
                    
                })
            }

        } else {
            if (this.state.from === "Advance") {
                let body = {
                    idAdvance: this.props.id,
                    idProject: this.props.idProject
                }
                this.postFavoriteAdvance(body).then((res) => {
                    this.setState({ isLoading: false, fav: true })
                    Swal.fire({
                        icon: 'success',
                        text: 'Avance añadido a favoritos exitosamente!',
                        confirmButtonColor:"#ffc107"
                    }).then(()=>{
                        window.location = `/project/${this.props.idProject}/advance`
                    })
                    
                })
            } else {
                let body = {
                    idMoodboard: this.props.id,
                    idProject: this.props.idProject
                }
                this.postFavoriteMoodboard(body).then((res) => {
                    this.setState({ isLoading: false, fav: true })
                    Swal.fire({
                        icon: 'success',
                        text: 'Moodboard añadido a favoritos exitosamente!',
                        confirmButtonColor:"#ffc107"
                    }).then(()=>{
                        window.location = `/project/${this.props.idProject}/moodboard`
                    })
                    
                })
            }
        }

    }

    render() {
        return (
            <div className="AddFav">
                <div className="d-flex aling-item-center">
                    {this.state.isLoading ?
                        this.props.from ?
                            <Loading disabledAnimation variant="dark" />
                            :
                            <Loading disabledAnimation />
                        :
                        <FontAwesomeIcon className={this.state.fav ? "icon-true" : "icon-false"} title={this.state.fav ? "Quitar de favoritos" : "Añadir a favoritos"} onClick={this.onClick} icon={faStar} />
                    }
                </div>
            </div>
        )
    }
}