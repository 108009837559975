import React from "react";
import ReactDOM from "react-dom";
import Footer from "./Components/Footer";
import './index.css'
import ListRoutes from "./ListRoutes";

ReactDOM.render(
    <>
        <ListRoutes />
        <Footer />
    </>,
    document.getElementById("root")
);
