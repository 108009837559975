import React from "react"
import BriefStudent from "./BriefStudent"
import BriefTeacher from "./BriefTeacher"
import "./Brief.css"

const Brief = ({ user }) => {

    return (
        <div className="Brief">
            {user.rol === "STUDENT" ?
                <BriefStudent user={user} />
                :
                <BriefTeacher user={user} />
            }
        </div>
    )
}

export default Brief