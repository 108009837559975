import React, { useEffect, useState } from "react"
import { Row, Col, Navbar, Container, NavItem, Nav } from "react-bootstrap";
import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import { Api } from "../../AWS/aws"
import Chat from "../../Components/Chat"
import Loading from "../../Components/Loading"
import "./Project.css"

const Project = ({ user }) => {

    const [location, setLocation] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [existAdvances, setExistAdvances] = useState(false)

    let useLocationPath = useLocation();

    const { id } = useParams()

    const getNroAdvances = (id) => {
        return Api({
            path: `/project/nroadvances/${id}`
        })
    }

    useEffect(() => {
        let arrPath = useLocationPath.pathname.split("/")
        if (arrPath.length === 3) {
            setLocation("")
        } else {
            setLocation(arrPath[3])
        }
    }, [useLocationPath]);

    useEffect(() => {
        getNroAdvances(id).then(res => {
            if (res.code === 0) {
                if (res.body !== "0") {
                    setExistAdvances(true)
                }
                setIsLoading(false)
            }
        })
    }, [])

    return (
        <div className="Project">
            {isLoading ?
                <Loading />
                :
                <>
                    <Row>
                        <Col style={{ maxHeight: "59px" }}>
                            <Navbar collapseOnSelect expand="lg">
                                <Container>
                                    <Link style={{ textDecoration: "none" }} to="/"><h1 className="titulo" style={{ marginLeft: "0.5rem" }}>Mis proyectos</h1></Link>
                                    <Navbar.Toggle className="me-3" />
                                    <Navbar.Collapse className="justify-content-end">
                                        <NavItem >
                                            <Nav.Link as={Link} eventKey={1} to="" ><h1 className={location === "" ? "titulo px-2 top activate" : "titulo px-2 top"}>Brief</h1></Nav.Link>
                                        </NavItem>
                                        <NavItem >
                                            <Nav.Link as={Link} eventKey={2} to="team" ><h1 className={location === "team" ? "titulo px-2 activate" : "titulo px-2"}>Equipo</h1></Nav.Link>
                                        </NavItem>
                                        <NavItem >
                                            <Nav.Link as={Link} eventKey={3} to="moodboard" ><h1 className={location === "moodboard" ? "titulo px-2 activate" : "titulo px-2"}>Moodboard</h1></Nav.Link>
                                        </NavItem>
                                        {
                                            existAdvances ?
                                                <NavItem >
                                                    <Nav.Link as={Link} eventKey={4} to="advance" ><h1 className={location === "advance" ? "titulo px-2 activate" : "titulo px-2"}>Avances</h1></Nav.Link>
                                                </NavItem>
                                                :
                                                <>
                                                    {user.rol === "TEACHER" ?
                                                        <NavItem >
                                                            <Nav.Link as={Link} eventKey={4} to="advance" ><h1 className={location === "advance" ? "titulo px-2 activate" : "titulo px-2"}>Avances</h1></Nav.Link>
                                                        </NavItem>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                        }
                                        <NavItem >
                                            <Nav.Link as={Link} eventKey={5} to="submission" ><h1 className={location === "submission" ? "titulo px-2 bottom activate" : "titulo px-2 bottom"}>Entregas</h1></Nav.Link>
                                        </NavItem>
                                        {user.rol === "TEACHER" ?
                                            <NavItem >
                                                <Nav.Link as={Link} eventKey={4} to="resume" ><h1 className={location === "resume" ? "titulo px-2 activate" : "titulo px-2"}>Resumen</h1></Nav.Link>
                                            </NavItem>
                                            :
                                            <></>
                                        }
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="project-outlet">
                                <Outlet />
                            </div>
                        </Col>
                    </Row>
                </>
            }
            <Chat user={user} />
        </div>
    )
}

export default Project