import Pool from "./UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export async function authUser() {

    return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession(async (err, session) => {
                if (err) {
                    reject()
                } else {
                    resolve(true)
                }
            })
        } else {
            reject()
        }
    })


}

export async function logout() {

    const user = Pool.getCurrentUser();
    if (user) {
        user.signOut();
    }


}


export async function changePassword(password, newPassword) {

    return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession(async (err, session) => {
                if (err) {
                    reject()
                } else {
                    user.changePassword(password, newPassword, (err, result) => {
                        if (err) {
                            console.error(err)
                            reject()
                        } else {

                            resolve(true)
                        }
                    })
                }
            })
        } else {
            reject()
        }
    })


}

export async function authenticate(Username, Password) {
    return await new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username,
            Pool
        })

        const authDetails = new AuthenticationDetails({
            Username,
            Password
        })

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("OnSuccess: ", data);
                resolve(data)
            },
            onFailure: (err) => {
                console.log("OnFailure: ", err);
                reject(err)
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequiered: ", data);
                resolve(data)
            }
        })
    })
}

export async function Api({
    path,
    method = "GET",
    headers = {},
    body
}) {

    body = body ? JSON.stringify(body) : body;

    const currentUser = Pool.getCurrentUser()
    if (currentUser === null) {
        return false;
    }

    const userToken = await getUserToken(currentUser);
    const url = "https://xmuad6au67.execute-api.us-east-1.amazonaws.com/dev" + path;

    headers["Authorization"] = userToken;
    headers["content-type"] = "application/json";

    const results = await fetch(url, {
        method,
        headers,
        body
    });

    if (results.status !== 200) {
        throw new Error(await results.text());
    }

    return results.json();
}

export async function getToken() {
    const currentUser = Pool.getCurrentUser()
    const userToken = await getUserAccessToken(currentUser);
    return userToken
}

function getUserToken(currentUser) {
    return new Promise((resolve, reject) => {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err);
                return;
            }
            resolve(session.getIdToken().getJwtToken());
        });
    });
}

function getUserAccessToken(currentUser) {
    return new Promise((resolve, reject) => {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err);
                return;
            }
            resolve(session.getAccessToken().getJwtToken());
        });
    });
}