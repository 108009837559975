import React from "react";
import { useState, useEffect } from "react";

export default function Countdown(props) {
    const { startingDays = 0, startingHours = 0, startingMinutes = 0, startingSeconds = 0 } = props;

    const [mins, setMinutes] = useState(startingMinutes);
    const [secs, setSeconds] = useState(startingSeconds);
    const [hours, setHours] = useState(startingHours);
    const [days, setDays] = useState(startingDays);

    useEffect(() => {
        let sampleInterval = setInterval(() => {
            if (secs > 0) {
                setSeconds(secs - 1);
            }
            if (secs === 0) {
                if (mins === 0) {
                    if (hours === 0) {
                        if (days === 0) {
                            clearInterval(sampleInterval);
                        } else {
                            setDays(days - 1)
                            setHours(23)
                            setMinutes(59);
                            setSeconds(59);
                        }

                    } else {
                        setHours(hours - 1)
                        setMinutes(59);
                        setSeconds(59);
                    }

                } else {
                    setMinutes(mins - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(sampleInterval);
        };
    });

    return (
        <div>
            <span>
                {
                    days === 0 ?
                        ""
                        :
                        days + " dias "
                }
                {
                    hours === 0 && days === 0 ?
                        ""
                        :
                        hours + " horas "
                }
                {
                    mins === 0 && hours === 0 && days === 0 ?
                        ""
                        :
                        mins + " minutos "
                }
                {
                    secs + " segundos "
                }
            </span>
        </div>
    );
}