import React, { Component } from "react"
import { useParams } from 'react-router-dom'
import './ModalEmail.css'
import Loading from "./Loading"
import { Api } from "../AWS/aws"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faXmark, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Form, Button } from "react-bootstrap";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ModalEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            to: [],
            editEmail: false,
            asunto:"",
            mensaje:"",

        };
    }

    postEmail(body) {
        return Api({
            path: `/email`,
            method:"POST",
            body:body
        })
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleOpen = () => {
        this.setState({ show: true, to: [...this.props.to], asunto:"", mensaje:"", editEmail:false })
    }

    submit = () => {
        let para= []

        this.state.to.forEach(data => {
            para.push(data.email)
        })

        let body = {
            mensaje: this.state.mensaje,
            asunto: this.state.asunto,
            para: para,
            de: this.props.user.name + " ("+this.props.user.email+")."
        }

        this.postEmail(body).then(res => {
            console.log("enviado");
            this.handleClose()
        })
    }

    async componentDidMount() {
        
    }

    render() {
        return (
            <>
                <FontAwesomeIcon className="email" onClick={this.handleOpen} icon={faEnvelope} />
                <Modal className="ModalEmail" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Correo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Para:
                                    {
                                        this.state.editEmail ?
                                            <FontAwesomeIcon className="backEmail" onClick={() => {
                                                this.setState({ to: [...this.props.to], editEmail: false })
                                            }} icon={faRotateLeft} />
                                            : ""
                                    }
                                </Form.Label>
                                {
                                    this.state.show ?
                                        this.state.to.map((data, index) => {
                                            return (
                                                <div key={index} className="m-1" style={{ position: "relative" }}>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="name@example.com"
                                                        value={data.email}
                                                        disabled
                                                    />
                                                    {
                                                        this.state.to.length === 1 ?
                                                            <></>
                                                            :
                                                            <FontAwesomeIcon className="deleteEmail" onClick={() => {
                                                                let res = this.state.to
                                                                res.splice(index, 1)
                                                                this.setState({ to: res, editEmail: true })
                                                            }} icon={faXmark} />
                                                    }

                                                </div>
                                            )
                                        })
                                        :
                                        <></>

                                }
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Asunto:</Form.Label>
                                <Form.Control onChange={(e)=>{this.setState({asunto:e.target.value})}}/>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Mensaje:</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e)=>{this.setState({mensaje:e.target.value})}}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="warning" onClick={this.submit}>
                            Enviar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withParams(ModalEmail);