import React, { Component } from "react"
import { Link, useParams } from 'react-router-dom'
import { Modal, Row, Col, FormControl, Button } from "react-bootstrap";
import './ModalInfo.css'
import Loading from "../../Components/Loading"
import { Api } from "../../AWS/aws"
import listExtension from "../../Assets/Js/listExtension"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import Picker from 'emoji-picker-react';
import AddFav from "../../Components/AddFav";
import dateFormat from "../../Assets/Js/dateFormat"
import Swal from 'sweetalert2'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class ModalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoadingAdvance: true,
            id: "",
            files: [],
            comment: "",
            newComment: "",
            comments: [],
            addFav: false,
            fav: false,
            showPicker: false

        };
    }

    getStudentAdvance(id) {
        return Api({
            path: `/student/advance/${id}`
        })
    }

    getAdvanceComments(id) {
        return Api({
            path: `/advance/comments/${id}`
        })
    }

    getStudentEntrega(id) {
        return Api({
            path: `/student/entrega/${id}`
        })
    }

    getEntregaComments(id) {
        return Api({
            path: `/entrega/comments/${id}`
        })
    }

    postAddComment(body) {
        return Api({
            path: `/student/comment`,
            body: body,
            method: "POST"
        })
    }

    deleteAdvance(id) {
        return Api({
            path: `/student/advance/${id}`,
            method: "DELETE"
        })
    }

    
    deleteSubmission(id) {
        return Api({
            path: `/student/entrega/${id}`,
            method: "DELETE"
        })
    }

    async componentDidMount() {
        if (this.props.from === "Avance") {
            const urlParams = new URLSearchParams(window.location.search);
            let addFav = urlParams.get('addFav');
            if (addFav) {
                this.setState({ addFav: true })
            }
            let fav = urlParams.get('fav');
            if (fav) {
                this.setState({ fav: true })
            }
            this.getStudentAdvance(this.props.params.idAdvance).then((res) => {
                console.log(res.body);
                if (res.code === 0) {
                    this.setState({ files: res.body.file, id: res.body.id, comment: res.body.comment, isLoading: false, addFav: addFav, isLoadingAdvance: false })
                }
            })
            this.getAdvanceComments(this.props.params.idAdvance).then((res) => {
                if (res.code === 0) {
                    this.setState({ comments: res.body, isLoading: false })
                }
            })
        } else {
            this.getStudentEntrega(this.props.params.idSubmission).then((res) => {
                if (res.code === 0) {
                    this.setState({ files: res.body.file, id: res.body.id, comment: res.body.comment, isLoading: false })
                }
            })
            this.getEntregaComments(this.props.params.idSubmission).then((res) => {
                if (res.code === 0) {
                    this.setState({ comments: res.body, isLoading: false })
                }
            })
        }
    }

    eliminar = () => {
        this.setState({ isSaving: true })
        if (this.props.from === "Entrega") {

            Swal.fire({
                title: '¿Esta seguro/a que desea eliminar la entrega?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                confirmButtonColor: "#dc3545",
                cancelButtonText:"Cancelar"
              }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteSubmission(this.state.id).then(res => {
                        if(res.code === 0){
                            Swal.fire({
                                icon: 'success',
                                text: 'Entrega eliminada exitosamente!',
                                confirmButtonColor:"#ffc107"
                            }).then(()=>{
                                window.location = "/project/" + this.props.params.id + "/submission"
                            })
                        }else{
                            this.setState({ isSaving: false })
                            Swal.fire({
                                icon: 'error',
                                text: 'Error al eliminar entrega!',
                                confirmButtonColor:"#dc3545"
                            })
                        }
                        
                        
                    })
                } else if (result.isDenied) {
                }
              })


            
        }else{

            Swal.fire({
                title: '¿Esta seguro/a que desea eliminar el avance?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                confirmButtonColor: "#dc3545",
                cancelButtonText:"Cancelar"
              }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteAdvance(this.state.id).then(res => {
                        if(res.code === 0){
                            Swal.fire({
                                icon: 'success',
                                text: 'Avance eliminado exitosamente!',
                                confirmButtonColor:"#ffc107"
                            }).then(()=>{
                                window.location = "/project/" + this.props.params.id + "/advance"
                            })
                        }else{
                            this.setState({ isSaving: false })
                            Swal.fire({
                                icon: 'error',
                                text: 'Error al eliminar avance!',
                                confirmButtonColor:"#dc3545"
                            })
                        }
                        
                        
                    })
                } else if (result.isDenied) {
                }
              })
            
        }
        
    }

    saveComment = () => {
        if (this.state.newComment !== "") {

            this.setState({ isSaving: true })
            let body = {}
            if (this.props.from === "Entrega") {
                body = {
                    comment: this.state.newComment,
                    idProject: this.props.params.id,
                    idRef: this.props.params.idSubmission
                }
            } else {
                body = {
                    comment: this.state.newComment,
                    idProject: this.props.params.id,
                    idRef: this.props.params.idAdvance
                }
            }

            this.postAddComment(body).then((res) => {
                if (res.code === 0) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Comentario añadido exitosamente!',
                        confirmButtonColor:"#ffc107"
                    })
                    let arr = this.state.comments
                    arr.push(res.body)
                    this.setState({ comments: arr, isSaving: false, newComment: "" })
                }
            })
        }
    }

    render() {
        return (
            <Modal show={true} backdrop="static" size="xl" className="modal ModalInfo">
                <Modal.Header>
                    <Modal.Title>{this.props.from === "Avance" ? <>Información del avance{this.state.addFav && <AddFav id={this.state.id} idProject={this.props.params.id} from="Advance" fav={this.state.fav} />}</> : "Información de la entrega"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loading variant="dark" />
                        :
                        <>
                            <h3> Documentos adjuntos</h3>
                            <div className="contenedor-doc">

                                <Row className="mb-3">
                                    <Col>
                                        <span>{this.state.comment}</span>
                                    </Col>
                                </Row>
                                <Row className="scroll-files">
                                    {this.state.files.map((data, index) => {
                                        let arr = data.split("/")
                                        let name = arr[arr.length - 1]
                                        arr = name.split(".")
                                        let extension = arr[arr.length - 1]
                                        let posExtension = listExtension.indexOf(extension)
                                        if (posExtension < 0) {
                                            extension = "archivo"
                                        }
                                        return (
                                            <Col key={"col " + index} className="col-file">
                                                <Row className="card-file">
                                                    <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer"><FontAwesomeIcon
                                                        className="col-delete"
                                                        icon={faEye}
                                                        title="Ver"
                                                    /></a>

                                                    <Col className="col-img">
                                                        <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                    </Col>
                                                    <Col className="col-name">{name}</Col>
                                                </Row>
                                            </Col>
                                        )
                                    })}
                                </Row>

                            </div>

                            <br />
                            <h3> Comentarios</h3>
                            <div className="contenedor-doc">
                                <div className="scroll-comment">
                                    {this.state.comments.length === 0 ?
                                        <Row>
                                            <Col >
                                                <div className="contenedor-msj" style={{ backgroundColor: "#00000000" }}>
                                                    <span>¡Se el primero en hacer un comentario!</span>
                                                </div>
                                            </Col>

                                        </Row>
                                        :
                                        this.state.comments.map((data, index) => (

                                            <Row key={"row " + index}>
                                                <Col >
                                                    <div className="contenedor-msj">
                                                        <div>
                                                            <Row>
                                                                <Col sm={8}>
                                                                    <p className="info-texto"><i>{data.name}</i></p>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <p>{dateFormat("dateTime", data.date)}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <p>
                                                            {data.comment}
                                                        </p>
                                                    </div>
                                                </Col>

                                            </Row>
                                        ))}
                                </div>


                                <Row>
                                    <Col>
                                        <div style={{ position: "relative" }}>
                                            <FormControl as="textarea" placeholder="Escribe un comentario..." rows={2} value={this.state.newComment} onChange={(event) => this.setState({ newComment: event.target.value })} />
                                            {
                                                this.state.showPicker ?
                                                    <div style={{ position: "absolute", top: "-325px", right: "0px" }}>
                                                        <Picker onEmojiClick={(event, emojiObject) => { this.setState({ newComment: this.state.newComment + emojiObject.emoji }) }} />
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="boton d-flex justify-content-end">
                                            <div style={{ fontSize: "30px" }} className="me-3">
                                                <FontAwesomeIcon title="Iconos" style={{ cursor: "pointer" }} icon={faFaceSmile} onClick={() => { this.setState({ showPicker: !this.state.showPicker }) }} />
                                            </div>
                                            <Button variant="warning" disabled={this.state.isSaving} onClick={this.saveComment}>Enviar</Button>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={this.state.isSaving} onClick={this.eliminar}>Eliminar</Button>
                    <Link to=".." className="btn btn-secondary">Cerrar</Link>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default withParams(ModalInfo);