import { faUser, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react"
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { Row, Col, Button, FormControl } from "react-bootstrap";
import { Api, changePassword } from "../../AWS/aws"
import "./Profile.css"
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading"
import ModalEmail from "../../Components/ModalEmail";

const Profile = ({ user }) => {

    const [edit, setEdit] = useState(false)

    const [contraseña, setContraseña] = useState("")
    const [nuevaContraseña, setNuevaContraseña] = useState("")
    const [isErrorPassword, setIsErrorPassword] = useState(false)
    const [perfil, setPerfil] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)


    const postImgProfile = (body) => {
        return Api({
            path: `/user`,
            body: body,
            method: 'POST'
        })
    }

    const getProfile = (id) => {
        return Api({
            path: `/user/${id}`
        })
    }

    const id = useParams().id

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            setIsLoading(true)
            if (id) {
                if (id === user.id) {
                    setIsLoading(false)
                    setPerfil(user)
                    setEdit(true)
                } else {
                    getProfile(id).then(res => {
                        if (res.code === 0) {
                            setPerfil(res.body)
                            setIsLoading(false)
                            setEdit(false)
                        } else {
                            setIsError(true)
                            setIsLoading(false)
                            setEdit(false)
                        }
                    })
                }

            } else {
                setIsLoading(false)
                setPerfil(user)
                setEdit(true)
            }
        }

    }, [user, id])



    const onClick = () => {
        setIsErrorPassword(false)
        changePassword(contraseña, nuevaContraseña).then(res => {
            window.location = "/login"
        }).catch((res) => {
            setIsErrorPassword(true)
        })
    }

    const uploadS3 = async (file, key) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGE4A6BCMS",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })

            parallelUploads3.on("httpUploadProgress", (process) => {

            })

            await parallelUploads3.done()

            postImgProfile({ urlImg: key }).then((res) => {
                window.location = "/profile"
            })

            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    return (
        <div className="Profile">
            <Row className="contenedor animate__animated animate__fadeInLeft">
                <h1 className="titulo animate__animated animate__fadeInDown mt-3 mb-5">Perfil {isLoading ? <></> : isError ? <></> : edit?<></>:<ModalEmail user={user} to={[perfil]} from={user} />}</h1>
                {isLoading ?
                    <div className="mb-5">
                        <Loading />
                    </div>
                    :
                    isError ?
                        <h2 style={{ color: "rgb(var(--color-7))", textAlign: "center" }} className="">Usuario no encontrado!</h2>
                        :
                        <>
                            <Col style={{ flex: "0 0 auto", width: "400px" }}>
                                <div className="d-flex justify-content-center">
                                    {perfil.urlImg ?
                                        <img width={150} height={150} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + perfil.urlImg} alt="foto perfil" />
                                        :
                                        <FontAwesomeIcon style={{ fontSize: "150px" }} icon={faUser} />
                                    }
                                </div>
                                {edit ?
                                    <div className="d-flex justify-content-center my-3">
                                        <Button variant="secondary" onClick={(e) => {
                                            let input = document.getElementById("inputFileImagen")
                                            input.click()
                                        }}>Cambiar foto</Button>
                                        <input
                                            id="inputFileImagen"
                                            type="file"
                                            hidden
                                            value={""}
                                            accept="image/*"
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    let date = Date.now()
                                                    let key = perfil.id + "/" + date + "/profile/" + e.target.files[0].name

                                                    uploadS3(e.target.files[0], key)
                                                }
                                            }}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                            </Col>
                            <Col>
                                <div style={{ fontSize: "25px" }}>
                                    <h2 style={{ color: "rgb(var(--color-1))" }} className="">Datos personales</h2>
                                    <div style={{ paddingLeft: "20px" }}>
                                        <p><b>Nombre:</b> {perfil.name}</p>
                                        <p><b>Correo:</b> {perfil.email}</p>
                                        <p><b>Rut:</b> {perfil.id}</p>
                                        <p><b>Rol:</b> {perfil.rol === "STUDENT" ? "Estudiante" : perfil.rol === "TEACHER"? "Profesor": "Ayudante"}</p>
                                    </div>
                                </div>
                                {
                                    edit ?
                                        <div style={{ fontSize: "25px" }} className="mt-3">
                                            <h2 style={{ color: "rgb(var(--color-1))" }} className="">Cambio de contraseña</h2>
                                            <p style={{ fontSize: "15px" }}>La nueva contraseña debe contener:</p>
                                            <ul style={{ fontSize: "15px" }}>
                                                <li>Un minimo de 8 caracteres</li>
                                                <li>Almenos una letra en mayúscula</li>
                                                <li>Almenos una letra en minúscula</li>
                                                <li>Almenos un número</li>
                                                <li>Almenos un signo</li>
                                            </ul>
                                            <div style={{ paddingLeft: "20px" }}>
                                                <p style={{ fontSize: "20px" }}>Contraseña actual:</p> <FormControl style={{ width: "330px" }} value={contraseña} onChange={(e) => { setContraseña(e.target.value) }} />
                                                <p style={{ fontSize: "20px" }}>Contraseña nueva: </p> <FormControl style={{ width: "330px" }} value={nuevaContraseña} onChange={(e) => { setNuevaContraseña(e.target.value) }} />
                                                {isErrorPassword ?
                                                    <p style={{ fontSize: "15px", color: "rgb(var(--color-24))" }}>¡Error al cambiar la contraseña!. Porfavor intente nuevamente.</p>
                                                    :
                                                    <></>
                                                }
                                                <Button variant="secondary" onClick={onClick} className="mt-3">Cambiar contraseña</Button>

                                            </div>
                                        </div>
                                        :
                                        <div style={{ marginBottom: "60px" }} className="mt-3">

                                        </div>
                                }
                            </Col>
                        </>
                }
            </Row>
        </div>
    )
}

export default Profile