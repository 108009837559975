import React from "react"
import MoodboardStudent from "./MoodboardStudent"
import MoodboardTeacher from "./MoodboardTeacher"
import "./Moodboard.css"

const Moodboard = ({ user }) => {

    return (
        <div className="Moodboard">
            {user.rol === "STUDENT" ?
                <MoodboardStudent user={user} />
                :
                <MoodboardTeacher user={user} />
            }
        </div>
    )
}

export default Moodboard