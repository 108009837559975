import { faFaceSadTear } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { Row, Col } from "react-bootstrap";
import "./NotFound.css"

const NotFound = ({ user }) => {

    return (
        <div className="NotFound">
            <Row style={{height:"10vh"}} className="text-center align-items-center">
                <Col>
                    <h1>¡Página no encontrada! <FontAwesomeIcon icon={faFaceSadTear}/></h1>
                </Col>
            </Row>
        </div>
    )
}

export default NotFound